import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Solatrix Pro Center
			</title>
			<meta name={"description"} content={"Якісне обслуговування, якому можна довіряти"} />
			<meta property={"og:title"} content={"Головна | Solatrix Pro Center"} />
			<meta property={"og:description"} content={"Якісне обслуговування, якому можна довіряти"} />
			<meta property={"og:image"} content={"https://solatrixpro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solatrixpro.com/img/3448677.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solatrixpro.com/img/3448677.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solatrixpro.com/img/3448677.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solatrixpro.com/img/3448677.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solatrixpro.com/img/3448677.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solatrixpro.com/img/3448677.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Solatrix Pro Center
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					font="normal 400 22px/1.1 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 80px 0px 80px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					У Solatrix Pro Center ми прагнемо надавати виняткові автомобільні послуги, приділяючи особливу увагу надійності, якості та задоволеності клієнтів. Наша команда досвідчених професіоналів готова гарантувати, що ваш автомобіль отримає найкращий догляд, незалежно від того, потрібне вам планове технічне обслуговування або серйозний ремонт.
				</Text>
				<Button
					margin="0px 0 50px 0px"
					padding="14px 35px 14px 35px"
					background="--color-primary"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-primary"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					border-radius="5px"
				>
					Контакти
				</Button>
			</Box>
			<Image
				src="https://solatrixpro.com/img/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://solatrixpro.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Про центр Solatrix Pro
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Solatrix Pro Center – це універсальне рішення для всіх ваших автомобільних потреб. Наше сучасне обладнання оснащене найновішими інструментами та технологіями, що дозволяє нам ефективно діагностувати та ремонтувати ваш автомобіль. Ми пишаємося своєю прозорістю, надаючи чітку та чесну інформацію про те, які послуги потрібні вашому автомобілю. Прагнучи досконалості, ми прагнемо побудувати довгострокові відносини з нашими клієнтами, надаючи першокласний сервіс при кожному вашому візиті.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						Чому варто вибрати автосервіс Solatrix Pro Center?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Досвідчені техніки
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Наша команда складається із сертифікованих фахівців з багаторічним досвідом роботи в автомобільній промисловості, що гарантує, що ваш автомобіль знаходиться у надійних руках.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Передові технології
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Ми використовуємо новітні діагностичні інструменти та обладнання для точного визначення та усунення проблем, мінімізуючи час простою та максимізуючи ефективність.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Клієнтоорієнтований підхід
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Ваше задоволення – наш пріоритет. Ми дослухаємося до ваших проблем та пропонуємо рішення з урахуванням ваших конкретних потреб.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Прозоре ціноутворення
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Жодних прихованих платежів чи несподіваних зборів. Ми надаємо чіткий кошторис та пояснюємо вартість робіт до їх початку.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Комплексні послуги для вашого автомобіля
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								В автосервісі Solatrix Pro Center пропонуємо широкий спектр послуг для підтримки безперебійної роботи вашого автомобіля. Від заміни масла та ремонту гальм до діагностики двигуна та заміни шин – наші досвідчені техніки навчені усьому. Ми розуміємо, що ваш автомобіль є важливою частиною вашого повсякденного життя, і прагнемо повернути вас на дорогу якнайшвидше без шкоди для якості.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Ваш надійний партнер з автосервісу
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							У Solatrix Pro Center ми дбаємо про підтримку вашого автомобіля в ідеальному стані. Завдяки нашій команді експертів та передовим технологіям ви можете їздити з упевненістю, знаючи, що про ваш автомобіль добре подбали. Зв'яжіться з нами сьогодні, щоб записатися на прийом, і дозвольте нам подбати про ваші автомобільні потреби.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						Якісне обслуговування, якому можна довіряти
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://solatrixpro.com/img/3.jpg) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline2"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Зв’яжіться з нами сьогодні
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Не чекайте, поки у вашого автомобіля виникнуть проблеми. Проактивне технічне обслуговування допоможе вам заощадити час та гроші у довгостроковій перспективі. Відвідайте центр Solatrix Pro та відчуйте різницю як обслуговування.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});